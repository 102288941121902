<template>
  <section class="video-widget grid">
    <div class="wrapper">
      <Component :is="isFirst ? 'h1' : 'h2'" class="title">
        <span v-html="nbsp(widget.title)" />
      </Component>

      <VideoIframe
        v-model:state="videoState"
        :video-id="widget.video_video_id"
        :is-vimeo="!!widget.video_is_vimeo"
        class="embed"
        tabindex="-1"
        @update:style="iframeStyle = $event"
        @focusin="handleIframeFocus"
        @focus="handleIframeFocus"
      />

      <Transition name="fade">
        <div
          v-if="widget.image && videoState !== 'playing'"
          class="placeholder"
          :style="iframeStyle"
          aria-hidden="true"
          @click="playVideo"
        >
          <BaseImage
            :image="widget.image"
            :width="900"
            :height="506"
            :img-sizes="[
              { w: 335, h: 185, resize: 'fill' },
              { w: 704, h: 389, resize: 'fill' },
              { w: 900, h: 506, resize: 'fill' }
            ]"
            fit="fill"
            :style="{ background: widget.image?.dominant_color || '#fcfcfc' }"
          />

          <span class="placeholder-btn wildwaffle">
            <span class="icon-wrap">
              <Play class="icon" role="img" />
            </span>

            <span v-text="t('play-video')" />
          </span>
        </div>
      </Transition>

      <button
        ref="toggleButton"
        class="toggle-button visually-hidden"
        :aria-label="
          videoState === 'playing' ? t('pause-video') : t('play-video')
        "
        @click="toggleState"
      />
    </div>
  </section>
</template>

<script lang="ts" setup>
import { StyleValue } from 'vue'

import Play from '@/assets/icons/play.svg?component'
import VideoIframe, { type VideoState } from '@/components/VideoIframe.vue'
import { PublicVideoWidget } from '@/service/__generated-api'
import { Widget } from '@/types/utils'

defineProps<Widget<PublicVideoWidget>>()

const { t } = useI18n()

const videoState = ref<VideoState>('paused')
const iframeStyle = ref<StyleValue>()

const toggleButton = ref<HTMLButtonElement>()

const toggleState = () => {
  videoState.value = videoState.value === 'playing' ? 'paused' : 'playing'
}

const handleIframeFocus = () => {
  toggleButton.value?.focus()
}

const playVideo = () => {
  videoState.value = 'playing'
}
</script>

<style lang="scss" scoped>
.wrapper {
  @include media-column(
    (
      xs: 4,
      md: 8,
      lg: 12
    )
  );

  display: grid;
  grid-template-columns: subgrid;
  row-gap: rem(16px);
  padding-top: rem(16px);

  border-top: $base-border;

  @include media-up(md) {
    row-gap: rem(24px);
  }

  @include media-up(lg) {
    padding-top: rem(12px);
  }

  @include media-up(xxl) {
    padding-top: rem(16px);
  }
}

.embed,
.placeholder {
  @include media-column(
    (
      xs: 4 -0.5,
      md: 8 -0.5
    )
  );
  grid-row: 1;
}

.placeholder {
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.placeholder-btn {
  @include focus-visible;
  @include center-content;
  @include center-self;
  @include font-size(
    (
      xs: 15px 1.33333
    )
  );

  gap: rem(16px);
  font-weight: 700;
  color: $white;
  text-transform: uppercase;

  @include media-up(md) {
    gap: rem(24px);
  }

  @include media-up(lg) {
    top: auto;
    bottom: rem(48px);
    left: rem(48px);
    transform: none;
  }

  .icon-wrap {
    @include center-content;
    @include size(rem(44px));
    background-color: $light-grey;

    @include media-up(lg) {
      @include size(rem(84px));
    }
  }
}

.title {
  @include media-column(
    (
      xs: 4 -0.5,
      md: 8 -0.5,
      lg: 4 7.5
    )
  );
  @include t-h4;

  @include media-up(md) {
    @include t-h1;
  }

  @include media-up(lg) {
    @include t-h2;
  }
}

.wrapper:has(.toggle-button:focus-visible) .placeholder {
  @include focus-outline;
}
</style>
